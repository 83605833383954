import React from "react"
// import { Link, graphql } from "gatsby"


import Layout from "../components/layout"

const CompetitionIndex = () => {

  return (
    <Layout title="主页">
    <h1>关于</h1>
    </Layout>
  )
}

export default CompetitionIndex


